import '../styles/common.css';
import Page from '../components/Page';

function Donate () {
    return (
        <Page page="Make a Donation" id="donate-page">
        </Page>
    );
}

export default Donate;