import React from 'react';
import Page from '../../components/Page';

const Mentor = () => {
  return (
    <Page id="mentor-page" page="Become a Mentor" className="involved-page">
    </Page>
  )
}

export default Mentor
