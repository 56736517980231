import React from 'react';
import Page from '../../components/Page';

const Buddy = () => {
  return (
    <Page page="Become a Buddy" id="buddy-page" className="involved-page">
    </Page>
  )
}

export default Buddy
