import React from 'react';
import Page
 from '../../components/Page';
const Chapter = () => {
  return (
    <Page id="chapter-page" page="Join a Chapter" className='involved-page'>
    </Page>
  )
}

export default Chapter


